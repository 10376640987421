import './App.css';
import React, {useState} from 'react';
import {SectorOverview} from './components/Screens/SectorOverview.js';
import {WelcomeScreen} from './components/Screens/WelcomeScreen.js';
import {ArchiveScreen} from './components/Screens/ArchiveScreen.js';
import {MissionScreen} from './components/Mobile/MissionScreen.js'
import {PersonScreen} from './components/Screens/PersonScreen.js';
import {Helmet } from 'react-helmet';
import { BrowserView, MobileView} from 'react-device-detect';
import {
  Grid, 
  GridItem,
  useDisclosure,
  SlideFade,
  Box,
} from '@chakra-ui/react'

function App() {
  const [screenId, setScreenId] = useState(0);
  const sectorDisclose = useDisclosure()
  const archiveDisclose = useDisclosure()
  const personsDisclose = useDisclosure()
  const setPageActive = (id) => {
    setScreenId(id);
    let openFunc = ()=>{};
    let closeFunc = ()=>{};
    switch (id) {
      case 1:
        openFunc = sectorDisclose.onOpen;
        closeFunc = () => { 
          archiveDisclose.onClose();
          personsDisclose.onClose();
        }
        break;
      case 2:
        openFunc = archiveDisclose.onOpen;
        closeFunc = () => { 
          sectorDisclose.onClose();
          personsDisclose.onClose();
        }
        break;
      case 3:
        openFunc = personsDisclose.onOpen;
        closeFunc = () => { 
          archiveDisclose.onClose();
          sectorDisclose.onClose();
        }
        break;
    }
    closeFunc();
    const delay = screenId === 0 ? 1200 : 300;
    setTimeout(() => {
      openFunc();
    }, delay)
  }

  let sectorClass = "hidden-menu ";
  let missionClass = "hidden-menu ";
  let personClass = "hidden-menu ";
  switch (screenId) {
    case 1:
      sectorClass = "";
      break;
    case 2:
      missionClass = "";
      break;
    case 3:
      personClass = "";
      break;
    default:
        break;
  }

  return (
    <div className="App">
      <Helmet>
        <meta name="robots" content="noindex"/>
        <meta property="og:url" content="www.prometheus-hub.de" />
        <meta property="og:type" content="Website" />
        <meta property="og:title" content="Sektor Quel-Theta" />
        <meta property="og:description" content="Übersicht des Quel-Theta Sektors" />
        <meta property="og:image" content="https://www.prometheus-hub.de/Prometheus746.png" />
      </Helmet>
      <header className="App-header">
      </header>
      <BrowserView>
        <div className="App-body">
          <Grid 
              height="100%"
              templateColumns="400px 1fr" 
              gap={"50px"}
              margin="0"
          >
            <GridItem>
              <WelcomeScreen setSelectedId={setPageActive} selectedId={screenId}></WelcomeScreen>
            </GridItem>
            <GridItem>
              <div className={sectorClass + "SubMenu-Container"}>
                <SlideFade in={sectorDisclose.isOpen} offsetY="20px">
                  <Box className="SubMenu-Background hideScroll sector-menu">
                    <SectorOverview enabled={screenId===1}></SectorOverview>
                  </Box>
                </SlideFade>
              </div>
              <div className={missionClass + "SubMenu-Container"}>
                <SlideFade in={archiveDisclose.isOpen} offsetY="20px">
                  <Box className="SubMenu-Background hideScroll Archive-Menu">
                    <ArchiveScreen enabled={screenId===2}></ArchiveScreen>
                  </Box>
                </SlideFade>
              </div>
              <div className={personClass + "SubMenu-Container"}>
                <SlideFade in={personsDisclose.isOpen} offsetY="20px">
                  <Box className="SubMenu-Background hideScroll Archive-Menu">
                    <PersonScreen enabled={screenId===3}></PersonScreen>
                  </Box>
                </SlideFade>
              </div>
            </GridItem>
          </Grid>
          <Box position="fixed" bottom="0" role="contentinfo" minWidth="100vw" mx="auto" py="2" px={{ base: '4', md: '8'}} fontSize="16px">&copy; Prometheus 3210</Box>
        </div>
      </BrowserView>
      <MobileView>
        <div className="App-mobile-body"><MissionScreen></MissionScreen></div>
      </MobileView>
    </div>
  );
}

export default App;
