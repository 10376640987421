import React, {useState} from 'react';
import { Grid, GridItem, Box, } from "@chakra-ui/react"
import {MenuButton} from '../Utils.js'
import { 
    BackgroundGeneric,
} from '../Backgrounds.js';

import {planets} from '../../Data.js';
import Typing from 'react-typing-animation';

function PlaceDescriptionText(props) {
    const positionText = [20, 20];

    return (
        <div className="text-container" style={{height: props.height, width: props.width-40}}>
            <svg height={props.height} width={props.width} className="shadow-light">
                <BackgroundGeneric height={props.height} width={props.width}></BackgroundGeneric>
            </svg>
            <div style={{left: positionText[0]}} className={"Text-Standard text-top"}>
                <Typing speed={1}>
                    {props.text}
                </Typing>
            </div>
        </div>
    )
}

export function PlaceDetails(props) {
    const [activeId, setActive] = useState(0);
    let xId = null;
    let yId = null;
    if (document.focusCoordinates){
        xId = document.focusCoordinates[0];
        yId = document.focusCoordinates[1];
    }

    let foundPlanet = null;
    if (props.isRequested) {
        for (let planet of planets) {
            if ( Number(xId) === Number(planet.position.x) && +yId === +planet.position.y) {
                foundPlanet = planet;
                break;
            }
        }
    }   
    
    let places = [];
    if (foundPlanet && foundPlanet.places) {
        places = foundPlanet.places;
    }

    let description = "";
    if (places[activeId]) {
        description = places[activeId].notes;
    }

    let buttons = places.map( (p_place, i) => {       
        return (
            <MenuButton key={"placeButton"+i} height={70} width={props.fullWidth - 48} state={""} setActive={() => {
                setActive(i);
            }}
            id={i} active={activeId===i} text={p_place.name}></MenuButton>
        );
        }   
    );

    return (
        <Grid h={props.fullHeight}
            w={props.fullWidth}
            templateColumns="repeat(15, 51px)" 
            templateRows="2fr 1fr"
            gap={props.gridGap + "px"}
            margin="0"
        >
            <GridItem className="SubMenu-Background hideScroll Archive-Menu"  colStart="1" colEnd="16" rowStart="1" rowEnd="2" overflow="auto"> 
                <Box >
                    <Grid  
                        gap="10px"
                        padding="15px"
                        margin="10px"
                    >
                        {buttons}
                    </Grid>
                </Box>
            </GridItem>
            <GridItem colStart="1" colEnd="16" rowStart="2" rowEnd="3" overflow="auto"> 
                <PlaceDescriptionText key={activeId} height={192} width={props.fullWidth} text={description} >
                </PlaceDescriptionText>
            </GridItem>
        </Grid>
    );
}