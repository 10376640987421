import React from 'react';
import {Planet} from "./Planet.js";
import {planets} from '../../Data.js';
import {MissionIcon} from './MissionIcon.js';

export function PlanetPreview(props) {
    const previewId="preview" + props.xId + "-" +props.yId;
    let visibility;
    if (props.inFocus) {
      visibility = "visible"; 
    }
    else {
      visibility = "hidden"
    }

    const offsetX = props.smallDia/2;
    const planetOffsetY = 0.1*(props.smallDia*2);
    const positionPlanet = [+props.position[0] + +offsetX, +props.position[1] + +planetOffsetY];
    
    const textOffsetY = 0.6*(props.smallDia*2);
    const positionText = [+props.position[0] + +offsetX, +props.position[1] + +textOffsetY];

    
    const missionOffsetX = +props.smallDia/2;
    const missionOffsetY = -props.smallDia/2;
    const positionMission = [+props.position[0] + +missionOffsetX, +props.position[1] + +missionOffsetY];
    
    let foundPlanet = null;
    for (let planet of planets) {
      if ( Number(props.xId) === Number(planet.position.x) && +props.yId === +planet.position.y) {
        foundPlanet = planet;
        break;
      }
    }
    let name =null;
    if (foundPlanet) {
      name = foundPlanet.name;
    }

    return(
      <g id={ previewId } visibility = {visibility} style={{cursor: "pointer"}}>
        <Planet position={positionPlanet} diameter={props.smallDia} smallImage={true} xId = {props.xId} yId = {props.yId}/>
        <text x={positionText[0]} y={positionText[1]} className="small Planet-Name-Preview" fill="white" textAnchor="middle">{name}</text>
        <MissionIcon xId={props.xId} yId={props.yId} x={positionMission[0]} y={positionMission[1]} size="50px" alt="Mission available" />
      </g>
    )
}