import React, { useState } from 'react';
import { Grid, GridItem } from "@chakra-ui/react"
import Typing from "react-typing-animation";
import {
    DetailsAttribute,      
} from './DetailParts.js';

import {
    useDisclosure,
} from "@chakra-ui/react"
import { 
    MenuButton} from "../Utils.js"
import { 
    BackgroundGeneric,
} from '../Backgrounds.js';
import {planets} from '../../Data.js';

function MissionShort(props) {
    const halfWidth = props.fullWidth/2 - 18

    let codename = null;
    let classis = null;
    let site = null;
    let secrecy = null;
    let danger = null;
    let reward = null;
    if (props.mission) {
        secrecy = props.mission.secrecy;
        codename = props.mission.codename;
        classis = props.mission.classis;
        site = props.mission.site;
        danger = props.mission.danger;
        reward = props.mission.reward;
    }

    return (
        <Grid h="196px"
            w={props.fullWidth}
            templateColumns="repeat(8, 1fr) 20px repeat(8, 1fr)" 
            templateRows="repeat(3, 60px)"
            gap="8px"
            margin="0"
        >
            <GridItem colStart="1" colEnd="8" > 
                <DetailsAttribute height={60} width={halfWidth} title="Codename" text={codename}></DetailsAttribute>
            </GridItem>
            <GridItem colStart="10" colEnd="17"> 
                <DetailsAttribute height={60} width={halfWidth} title="Classis" text={classis}></DetailsAttribute>
            </GridItem>
            <GridItem colStart="1" colEnd="8" > 
                <DetailsAttribute height={60} width={halfWidth} title="Standort" text={site}></DetailsAttribute>
            </GridItem>
            <GridItem colStart="10" colEnd="17"> 
                <DetailsAttribute height={60} width={halfWidth} title="Geheimst." text={secrecy}></DetailsAttribute>
            </GridItem>
            <GridItem colStart="1" colEnd="8" > 
                <DetailsAttribute height={60} width={halfWidth} title="Gefahr" text={danger}></DetailsAttribute>
            </GridItem>
            <GridItem colStart="10" colEnd="17"> 
                <DetailsAttribute height={60} width={halfWidth} title="Bonus" text={reward}></DetailsAttribute>
            </GridItem>
        </Grid>
    );
}

function MissionDescriptionField(props){
    const textWidth = props.width - 310;
    

    let primaryObj = null;
    let secondaryObj = null;
    let addInfo = null;
    let support = null;
    let resolution = null;
    if (props.mission) {
        primaryObj = props.mission.primaryObj;
        secondaryObj = props.mission.secondaryObj;
        addInfo = props.mission.additionalInfo;
        support = props.mission.support;
        resolution = props.mission.resolution;

    }

    const [activeId, setActive] = useState(0)
    let resolutionEle = null;
    if (props.showResolution)
    {
        console.log("showing resolution")
        resolutionEle = (
        <GridItem colStart="1" colEnd="2" > 
            <MenuButton height={70} width={300} text={["Ergebnis"]} setActive={setActive} active={activeId === 4} id={4}></MenuButton>
        </GridItem>
        )
    }

    let text;
    switch (activeId) {
        case 0:
            text = primaryObj;
            break;
        case 1:
            text = secondaryObj;
            break;
        case 2:
            text = addInfo;
            break;
        case 3:
            text = support;
            break;
        case 4:
            text = resolution;
        default:
            console.log("Unexpected Active ID: " + activeId)
    }

    return (
        <Grid h={props.height}
            w={props.width}
            templateColumns="300px 1fr" 
            templateRows="70px 70px 70px 70px 1fr"
            gap="10px"
            margin="0"
        >
            <GridItem colStart="1" colEnd="2" > 
                <MenuButton height={70} width={300} text={["Ziel: Primär"]} setActive={setActive} active={activeId === 0} id={0}></MenuButton>
            </GridItem>
            <GridItem colStart="1" colEnd="2" > 
                <MenuButton height={70} width={300} text={["Ziel: Sekundär"]} setActive={setActive} active={activeId === 1} id={1}></MenuButton>
            </GridItem>
            <GridItem colStart="1" colEnd="2" > 
                <MenuButton height={70} width={300} text={"Weitere\nInformationen"} setActive={setActive} active={activeId === 2} id={2}></MenuButton>
            </GridItem>
            <GridItem colStart="1" colEnd="2" > 
                <MenuButton height={70} width={300} text={["Support"]} setActive={setActive} active={activeId === 3} id={3}></MenuButton>
            </GridItem>
            {resolutionEle}
            <GridItem colStart="2" colEnd="3" rowStart="1" rowEnd="5" > 
                <MissionDescriptionText key={activeId} height={props.height} width={textWidth} text={text}></MissionDescriptionText>
            </GridItem>
        </Grid>
    );
}

function MissionDescriptionText(props) {
    const positionText = [20, 20];
    return (
        <div className="text-container" style={{height: props.height, width: props.width-40}}>
            <svg height={props.height} width={props.width} className="shadow-light">
                <BackgroundGeneric height={props.height} width={props.width}></BackgroundGeneric>
            </svg>
            <div style={{left: positionText[0]}} className={"Text-Standard text-top"}>
                <Typing speed={1}>
                    {props.text}
                </Typing>
            </div>
        </div>
    )
}


export function MissionDetails(props) {
    const { isOpen, onOpen} = useDisclosure()

    let foundMission = null;
    if (props.mission) {
        foundMission = props.mission;
    }
    else {
        let xId = null;
        let yId = null;
        if (document.focusCoordinates){
            xId = document.focusCoordinates[0];
            yId = document.focusCoordinates[1];
        }
    
        if (props.isRequested) {
            for (let planet of planets) {
                if ( Number(xId) === Number(planet.position.x) && +yId === +planet.position.y) {
                    foundMission = planet.mission;
                    break;
                }
            }
            if (foundMission && !isOpen) {
                onOpen();
            }
        }  
    }
 
    
    const textHeight = props.fullHeight - (+196+10+2+10);

    return (
        <Grid h={props.fullHeight}
            w={props.fullWidth}
            templateColumns="300px 1fr" 
            templateRows="196px 2px 1fr"
            gap="10px"
            margin="0"
        >
            <GridItem colStart="1" colEnd="3" > 
                <MissionShort fullWidth={props.fullWidth} mission={foundMission}></MissionShort>
            </GridItem>
            <GridItem colStart="1" colEnd="2" > 
            </GridItem>
            <GridItem colStart="1" colEnd="2" > 
                <MissionDescriptionField height={textHeight} showResolution={props.showResolution} width={props.fullWidth} mission={foundMission}></MissionDescriptionField>
            </GridItem>
        </Grid>
    );
}