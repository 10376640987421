import React, { useState } from 'react';
import { Grid, Box } from "@chakra-ui/react"

import { 
    MenuButton} from "../Utils.js"
import { 
    BackgroundTitle,
} from '../Backgrounds.js';
import {MissionAttributes} from './MissionAttributes.js'
import {MissionText} from './MissionText.js'

export function MissionHeader(props) {
    return (
        <Box className="text-container" style={{height: props.height}}>
            <svg width={props.width} height={props.height} className={"shadow-light"}>
                <BackgroundTitle height={props.height} width={props.width}/>
            </svg>
            <div className="text-centered text-mobile-mission-header">{props.text}</div>
        </Box>
    );
}

export function MissionDetails(props) {
    const [activeId, setActive] = useState(0)

    let foundMission = null;
    if (props.mission) {
        foundMission = props.mission;
    }
    const width = props.fullWidth;
    const contentWidth = width-30; 
    const details = (
        <div>
            <MissionHeader
                height = {50}
                width = {width}
                text = {foundMission.codename}>
            </MissionHeader>
            <Grid 
            gap="10px"
            padding="15px"
            >
                <MenuButton height={70} width={contentWidth} text={["Eigenschaften"]} setActive={setActive} active={false} id={1}></MenuButton>
                <MenuButton height={70} width={contentWidth} text={["Ziel: Primär"]} setActive={setActive} active={false} id={2}></MenuButton>
                <MenuButton height={70} width={contentWidth} text={["Ziel: Sekundär"]} setActive={setActive} active={false} id={3}></MenuButton>
                <MenuButton height={70} width={contentWidth} text={"Weitere\nInformationen"} setActive={setActive} active={false} id={4}></MenuButton>
                <MenuButton height={70} width={contentWidth} text={["Support"]} setActive={setActive} active={false} id={5}></MenuButton>
            </Grid>
            
            <div>
                <MenuButton height={70} width={props.fullWidth} text={["Zurück"]} setActive={props.backFunction} active={false} id={4}></MenuButton>
            </div>
        </div>
    );
    const attributes = (
            <MissionAttributes 
                backFunction={()=>{setActive(0)}}
                fullWidth = {width}
                mission = {foundMission}>
            </MissionAttributes>

    );
    
    const primary = (
        <MissionText 
            backFunction={()=>{setActive(0)}}
            fullWidth = {width}
            title = "Ziel: Primär"
            text = {foundMission.primaryObj}>
        </MissionText>
    );
    
    const secondary = (
        <MissionText 
            backFunction={()=>{setActive(0)}}
            fullWidth = {width}
            title = "Ziel: Sekundär"
            text = {foundMission.secondaryObj}>
        </MissionText>
    );
    
    const addInfo = (
        <MissionText 
            backFunction={()=>{setActive(0)}}
            fullWidth = {width}
            title = {"Weitere\nInformationen"}
            text = {foundMission.additionalInfo}>
        </MissionText>
    );
    
    const support = (
        <MissionText 
            backFunction={()=>{setActive(0)}}
            fullWidth = {width}
            title = "Support"
            text = {foundMission.support}>
        </MissionText>
    );

    let shownElement;
    switch (activeId) {
        case 0:
        default:
            shownElement = details;
            break;
        case 1:
            shownElement = attributes;
            break;
        case 2:
            shownElement = primary;
            break;
        case 3:
            shownElement = secondary;
            break;
        case 4:
            shownElement = addInfo;
            break;
        case 5:
            shownElement = support;
            break;
    }
    
    return (
        <div>
            {shownElement}
        </div>
    );
}
