import React from 'react';
import {HexRow} from "./HexRow.js";
import { PlanetModal } from './PlanetModal.js';

// z-axis
// https://codepen.io/osublake/pen/YXoEQe

export class HexField extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        height: props.height,
        width: props.width,
        smallDia: props.smallDia,
        isRequested: false,
      }
    }
  
    componentDidMount() {
        document.title="Sektor Quel-Theta";
    }

    handleClick() {
      this.setState({isRequested: true});
    }

    closePreview() {
        this.setState({isRequested: false})
    }
  
    render() {
      const smallRad = this.state.smallDia/2;
      const bigRad = smallRad/ Math.cos(Math.PI/6);
  
      const rowsArr = Array(Number(this.state.height)).fill(this.state.width);
      const rows = rowsArr.map( (width, i) => {
          const actualI = i+1;
          let key = "hexRow " +actualI; 
          return (
          <HexRow key={key} yId={actualI} posY={i*92+smallRad+1} number={this.state.width} smallDia={this.state.smallDia}></HexRow>);
        }
      );
  
      const actualWidth = (Number(this.state.smallDia)+6)*this.state.width + Number(smallRad);
      const totalWidth = actualWidth + Number(this.state.smallDia);
      const totalHeight = (Number(bigRad)*3/2+12)*this.state.height + Number(bigRad)*2;
      
      const requested = this.state.isRequested;
      return(
          <div>
            <svg length="auto" width={totalWidth} height={totalHeight} id="field" className="shadow-medium">
                {rows}
                <use id="focusHex" onClick={() => this.handleClick()}></use>
                <use id="focusPreview" onClick={() => this.handleClick()}></use>
            </svg>
            <PlanetModal isRequested={requested} endRequest={() => this.closePreview()}></PlanetModal>
          </div>
      )
    }
  }