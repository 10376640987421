import React from 'react';
import {planets} from '../../Data.js';

export class Planet extends React.Component {
    constructor(props) {
      super(props);
      let position;
      if (props.position) {
        position = props.position;
      }
      else {
        position = [props.diameter/2,props.diameter/2]
      }

      this.state = {
        smallImage: props.smallImage ? props.smallImage : false,
        xId: props.xId,
        yId: props.yId,
        position: position,
        diameter: props.diameter
      }
    }
    render() {
      // Centerpoint
      
      // diameter of planet = small rad of hex
      const smallRad = this.state.diameter;
      const yOffset = -smallRad/2;
      const xOffset = -smallRad/2;
      let foundPlanet = null;
      for (let planet of planets) {
        if ( Number(this.state.xId) === Number(planet.position.x) && +this.state.yId === +planet.position.y) {
          foundPlanet = planet;
          break;
        }
      }
  
      if (foundPlanet) {
        let image = foundPlanet.image;
        if (this.state.smallImage) {
          image = foundPlanet.previewImage;
        }
        return (
          <image href={image} x={this.state.position[0] + xOffset} y={this.state.position[1] + yOffset} height={this.state.diameter} width={this.state.diameter}>
  
            </image>
        )
      }
      return (null);
    }
  }