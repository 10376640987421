import React from 'react';
import {planets} from '../../Data.js';

export function MissionIcon(props) {
    let foundMission = null;
    for (let planet of planets) {
      if ( Number(props.xId) === Number(planet.position.x) && +props.yId === +planet.position.y) {
        foundMission = planet.mission;
        break;
      }
    }

    if (foundMission) {
      return (
        <image x={props.x} y={props.y} height={props.size} width={props.size} href='/Mission Icon.png' alt={props.alt}>
          <title>I'm a square</title>
        </image>
      )
    }
    else {
      return(null);
    }
  }