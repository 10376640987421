import React, {useState, useRef, useEffect} from 'react';
import {MissionDetails} from './MissionDetails.js';
import {MissionList} from './MissionList.js';
import {MissionHeader} from './MissionDetails.js';


export function MissionScreen(props) {
    const [ mission, setMission ] = useState(null);
    const [ width, setWidth ] = useState(0);
    const ref = useRef(null);
    useEffect(() => {
        setWidth(ref.current ? ref.current.offsetWidth : 0);
    }, [ref.current]);

    if (mission){
        return (
            <div ref={ref}>
                <MissionDetails 
                    backFunction = {() => setMission(null)}
                    fullWidth = {width}
                    mission = {mission}
                ></MissionDetails>
            </div>
        )

    }

    return (
        <div ref={ref}>
            <MissionHeader
                height = {50}
                width = {width}
                text = {"Missionen"}>
            </MissionHeader>
            <MissionList setMission={setMission}>
            </MissionList>
        </div>
    )
}