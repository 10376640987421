import React from 'react';
import { Grid, GridItem, } from "@chakra-ui/react"
import {
    DetailsMainBody, 
    DetailsAttributeSet, 
    DetailsSubtitle, 
    DetailsPlanet 
} from './DetailParts.js';

import {
    useDisclosure,
} from "@chakra-ui/react"
import {planets} from '../../Data.js';

export function PlanetDetails(props) {
    const { isOpen, onOpen } = useDisclosure()
    let xId = null;
    let yId = null;
    if (document.focusCoordinates){
        xId = document.focusCoordinates[0];
        yId = document.focusCoordinates[1];
    }

    let foundPlanet = null;
    if (props.isRequested) {
        for (let planet of planets) {
            if ( Number(xId) === Number(planet.position.x) && +yId === +planet.position.y) {
                foundPlanet = planet;
                break;
            }
        }
        if (foundPlanet && !isOpen) {
            onOpen();
        }
    }   
    
    let description = null;
    let atmos = null;
    let prometheus = null;
    let population = null;
    let technology = null;
    if (foundPlanet) {
        description = foundPlanet.description;
        atmos = foundPlanet.atmo;
        prometheus = foundPlanet.prometheus;
        population = foundPlanet.population;
        technology = foundPlanet.technology;
    }

    const mainWidth = props.fullWidth - props.planetWidth - 24 

    return (
        <Grid h={props.fullHeight}
            w={props.fullWidth}
            templateColumns="repeat(15, 51px)" 
            templateRows="2fr 1fr"
            gap={props.gridGap + "px"}
            margin="0"
        >
            <GridItem colStart="1" colEnd="6"> 
                <DetailsPlanet width={props.planetWidth} height={props.mainHeight} xId={xId} yId={yId}/>
            </GridItem>
            <GridItem colStart="6" colEnd="16"> 
                <Grid
                    h={props.mainHeight}
                    w={props.descWidth}
                    templateColumns={props.subtitleWidth + "px repeat(2, 1fr)"} 
                    templateRows={props.subtitleHeight + "px 1fr"}
                    gap={props.descGap + "px"}
                >
                    <GridItem rowStart="1" rowEnd="2"> 
                        <DetailsSubtitle height={props.subtitleHeight} width={props.subtitleWidth} text={["Details"]}></DetailsSubtitle>
                    </GridItem>
                    <GridItem rowStart="2" rowEnd="3" colStart="1" colEnd="4"> 
                        <DetailsMainBody height={props.mainHeight - props.subtitleHeight - props.descGap} width={mainWidth} text={description}></DetailsMainBody>
                    </GridItem>
                </Grid>
            </GridItem>
        
                
            <GridItem colStart="1" colEnd="16"> 
                <DetailsAttributeSet 
                    prometheus={prometheus}
                    atmosphere={atmos}
                    population={population}
                    technology={technology}
                    width={props.fullWidth} />
            </GridItem>
        </Grid>
    );
}