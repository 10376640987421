import React from 'react';
import { BackgroundGeneric, BackgroundTitle, BackgroundAttributeTitle, BackgroundAttributeValue } from '../Backgrounds.js';
import { 
    Center,
    Grid,
    GridItem 
} from "@chakra-ui/react"
import {Planet} from "./Planet.js";
import Typing from 'react-typing-animation';

export function DetailsTitle(props) {

    return (
        <div className="text-container" style={{height: props.height}}>
            <svg zIndex="-1" style={{position:"absolute"}}  width={props.width} height={props.height}>
                <BackgroundTitle height={props.height} width={props.width}/>
            </svg>
            <div style={{left: props.height+10}} className="text-centered-vertical Planet-Name-Title" fill="white" >{props.text}</div>
        </div>
    );
}

export function DetailsMainBody(props) {
    const positionText = [20, 20];

    return (
        <div className="text-container" style={{height: props.height, width: props.width-40}}>
            <svg height={props.height} width={props.width}>
                <BackgroundGeneric height={props.height} width={props.width}></BackgroundGeneric>
            </svg>
            <div style={{left: positionText[0]}} className={"Text-Standard text-top"}>
                <Typing speed={1}>
                    {props.text}
                </Typing>
            </div>
        </div>
    )
}

export function DetailsSubtitle(props) {
    return (
        <div className="text-container" style={{height: props.height}}>
            <svg height={props.height} width={props.width}>
                <BackgroundGeneric height={props.height} width={props.width}></BackgroundGeneric>
            </svg>
            <div className={"Text-Standard text-centered"}>{props.text}</div>
        </div>
    )
}

export function DetailsAttribute(props) {
    const goldRatio = (1+Math.sqrt(5))/2;
    const textWidth = props.width/goldRatio - 20;
    const titleWidth = props.width -textWidth - 20;

    // For some reason not centered, so offset by 3
    const positionTitle = [20, props.height/2 + 3];
    let positionText =[titleWidth + 40, props.height/2+ 3];
    const className = props.className ? props.className : "Text-Standard"
    
    return (
        <div className="text-container" style={{height: props.height}}>
            <svg height={props.height} width={props.width} className="shadow-light" >
                <BackgroundAttributeTitle height={props.height} width={titleWidth}></BackgroundAttributeTitle>
                <BackgroundAttributeValue height={props.height} width={textWidth} offset={titleWidth + 20}></BackgroundAttributeValue>
            </svg>
            <div style={{left: positionText[0]}} className={className + " text-centered-vertical"}>
                <Typing speed={1}>
                    {props.text}
                </Typing>
            </div>
            <div style={{left: positionTitle[0]}} className={className + " text-centered-vertical"}>{props.title}</div>
        </div>
    )
}

export function DetailsPlanet(props) {
    return (
        <Center w={props.width} h={props.height} bg="rgba(13,13,16,0.8)" border="2px solid aliceblue" borderRadius="10px">
        <div >
            <svg length="auto" width="180px" height="180px" >
                <Planet 
                    xId={+props.xId}
                    yId={+props.yId}
                    
                    diameter="180"></Planet>
            </svg>
        </div>
    </Center>
    );
}

export function DetailsAttributeSet(props) {


    return (
        <Grid
        templateColumns="repeat(15, 1fr)" 
        templateRows="repeat(4, 60px)"
        gap={2}
    >
        
        <GridItem colStart="1" colEnd="16" > 
            <DetailsAttribute height={60} width={props.width} title="Prometheus" text={props.prometheus}></DetailsAttribute>
        </GridItem>
        <GridItem colStart="1" colEnd="16"> 
            <DetailsAttribute height={60} width={props.width} title="Atmosphäre" text={props.atmosphere}></DetailsAttribute>
        </GridItem>
        <GridItem colStart="1" colEnd="16"> 
            <DetailsAttribute height={60} width={props.width} title="Bevölkerung" text={props.population}></DetailsAttribute>
        </GridItem>
        <GridItem colStart="1" colEnd="16"> 
            <DetailsAttribute height={60} width={props.width} title="Technologie" text={props.technology}></DetailsAttribute>
        </GridItem>
    </Grid>
    );
}