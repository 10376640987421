import React from 'react';
import {HexField} from '../SectorOverview/HexField.js';

export function SectorOverview(props) {
    if (props.enabled) {
        return (
            <div >
                <p className="App-Title">
                    Sektor Quel-Theta
                </p>
                <HexField height="8" width="10" smallDia="100"/>
            </div>
        )
    }

    return null;
}