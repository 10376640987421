import React, { useState } from 'react';
import { Grid, GridItem } from "@chakra-ui/react"
import {
    DetailsAttribute,      
} from './DetailParts.js';
import { 
    MenuButton
    } from "../Utils.js"
import { 
    BackgroundGeneric,
} from '../Backgrounds.js';
import Typing from 'react-typing-animation';

function PersonShort(props) {
    const halfWidth = props.fullWidth/2 - 18

    let homeplanet = null;
    let work = null;
    if (props.person) {
        console.log(props.person.homeplanet)
        homeplanet = props.person.homeplanet.name;
        work = props.person.work;
    }

    return (
        <Grid h="60px"
            w={props.fullWidth}
            templateColumns="repeat(8, 1fr) 20px repeat(8, 1fr)" 
            templateRows="repeat(1, 60px)"
            gap="8px"
            margin="0"
        >
            <GridItem colStart="1" colEnd="8" > 
                <DetailsAttribute height={60} width={halfWidth} title="Heimat" text={homeplanet}></DetailsAttribute>
            </GridItem>
            <GridItem colStart="10" colEnd="17"> 
                <DetailsAttribute height={60} width={halfWidth} title="Beruf" text={work}></DetailsAttribute>
            </GridItem>
        </Grid>
    );
}

function PersonDescriptionField(props){
    const textWidth = props.width - 310;
    

    let organization = null;
    let addInfo = null;
    if (props.person) {
        organization = props.person.organization;
        addInfo = props.person.additionalInfo;

    }

    const [activeId, changeActive] = useState(0)
    const setActive = (id) => {
        changeActive(id);

        const animateTexts = document.getElementsByClassName("personDetailsAnimation");
        console.log(animateTexts);
        for (let textElement of animateTexts) {
            textElement.beginElement();
        }
        
    }

    let text;
    switch (activeId) {
        case 0:
            text = organization;
            break;
        case 1:
            text = addInfo;
            break;
        default:
            console.log("Unexpected Active ID: " + activeId)
    }

    return (
        <Grid h={props.height}
            w={props.width}
            templateColumns="300px 1fr" 
            templateRows="70px 70px 70px 70px 1fr"
            gap="10px"
            margin="0"
        >
            <GridItem colStart="1" colEnd="2" > 
                <MenuButton height={70} width={300} text={["Organisation"]} setActive={setActive} active={activeId === 0} id={0}></MenuButton>
            </GridItem>
            <GridItem colStart="1" colEnd="2" > 
                <MenuButton height={70} width={300} text={["Sonstige Notizen"]} setActive={setActive} active={activeId === 1} id={1}></MenuButton>
            </GridItem>
            <GridItem colStart="2" colEnd="3" rowStart="1" rowEnd="5" > 
                <PersonDescriptionText key={activeId} height={props.height} width={textWidth} text={text}></PersonDescriptionText>
            </GridItem>
        </Grid>
    );
}

function PersonDescriptionText(props) {
    const positionText = [20, 20];

    return (
        <div className="text-container" style={{height: props.height, width: props.width-40}}>
            <svg height={props.height} width={props.width} className="shadow-light">
                <BackgroundGeneric height={props.height} width={props.width}></BackgroundGeneric>
            </svg>
            <div style={{left: positionText[0]}} className={"Text-Standard text-top"}>
                <Typing speed={1}>
                    {props.text}
                </Typing>
            </div>
        </div>
    )
}


export function PersonDetails(props) {

    const textHeight = props.fullHeight - (+60+10+2+10);

    return (
        <Grid h={props.fullHeight}
            w={props.fullWidth}
            templateColumns="300px 1fr" 
            templateRows="60px 2px 1fr"
            gap="10px"
            margin="0"
        >
            <GridItem colStart="1" colEnd="3" > 
                <PersonShort fullWidth={props.fullWidth} person={props.person}></PersonShort>
            </GridItem>
            <GridItem colStart="1" colEnd="2" > 
            </GridItem>
            <GridItem colStart="1" colEnd="2" > 
                <PersonDescriptionField height={textHeight} showResolution={props.showResolution} width={props.fullWidth} person={props.person}></PersonDescriptionField>
            </GridItem>
        </Grid>
    );
}