import React from 'react';
import { Grid} from "@chakra-ui/react"

import { 
    MenuButton} from "../Utils.js"
import { 
    BackgroundGeneric,
} from '../Backgrounds.js';
import Typing from 'react-typing-animation';
import {MissionHeader} from './MissionDetails.js';


function MissionShort(props) {
    const width = props.fullWidth - 30
    const attrHeight = width/8

    let codename = null;
    let classis = null;
    let site = null;
    let secrecy = null;
    let danger = null;
    let reward = null;
    if (props.mission) {
        secrecy = props.mission.secrecy;
        codename = props.mission.codename;
        classis = props.mission.classis;
        site = props.mission.site;
        danger = props.mission.danger;
        reward = props.mission.reward;
    }

    return (
        <Grid 
        gap="10px"
        padding="15px"
        >
            <DetailsAttributeMobile height={attrHeight} width={width} title="Codename" text={codename} className="text-mobile-button"></DetailsAttributeMobile>
            <DetailsAttributeMobile height={attrHeight} width={width} title="Classis" text={classis} className="text-mobile-button"></DetailsAttributeMobile>
            <DetailsAttributeMobile height={attrHeight} width={width} title="Standort" text={site} className="text-mobile-button"></DetailsAttributeMobile>
            <DetailsAttributeMobile height={attrHeight} width={width} title="Geheimstufe" text={secrecy} className="text-mobile-button"></DetailsAttributeMobile>
            <DetailsAttributeMobile height={attrHeight} width={width} title="Gefahr" text={danger} className="text-mobile-button"></DetailsAttributeMobile>
            <DetailsAttributeMobile height={attrHeight} width={width} title="Bonus" text={reward} className="text-mobile-button"></DetailsAttributeMobile>
        </Grid>
    );
}

export function MissionAttributes(props) {

    let foundMission = null;
    if (props.mission) {
        foundMission = props.mission;
    }
 
    
    return (
        
        <div>
            <MissionHeader
                height = {50}
                width = {props.fullWidth}
                text = {"Eigenschaften"}>
            </MissionHeader>
            <MissionShort fullWidth={props.fullWidth} mission={foundMission}></MissionShort>
            <div>
                <MenuButton height={70} width={props.fullWidth} text={["Zurück"]} setActive={props.backFunction} active={false} id={4}></MenuButton>
            </div>
        </div>
    );
}

export function DetailsAttributeMobile(props) {

    // For some reason not centered, so offset by 3
    const positionTitle = 20;
    const className = props.className ? props.className : "Text-Standard"

    return (
        <div>
            <div className="text-container" style={{height: props.height, width: props.width-40}}>
                <svg height={props.height} width={props.width} className="shadow-light">
                    
                    <BackgroundGeneric height={props.height} width={props.width} cutLength={props.height/4}></BackgroundGeneric>
                </svg>
                <div style={{left: positionTitle}} className={className + " text-centered-vertical"}>{props.title}</div>
            </div>
            <div className="text-container" style={{height: props.height+20, width: props.width-40}}>
                <svg height={props.height+20} width={props.width} className="shadow-light">
                    <BackgroundGeneric positionX={30} positionY={10} height={props.height} width={props.width-30} cutLength={props.height/4}></BackgroundGeneric>
                </svg>
                <div style={{left: positionTitle+30}} className={className + " text-centered-vertical"}>
                    <Typing speed="1">
                        {props.text}
                    </Typing>
                </div>
            </div>
        </div>
    )
}