import React, {useState} from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from "@chakra-ui/react"
import {PlanetDetails} from './PlanetDetails.js';
import {PlanetHeader} from './PlanetHeader.js';
import {planets} from '../../Data.js';
import {MissionDetails} from './MissionDetails.js';
import {PlaceDetails} from './PlaceDetails.js'

export function PlanetModal(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [bodyType, setBody] = useState("details");
    let xId = null;
    let yId = null;
    if (document.focusCoordinates){
        xId = document.focusCoordinates[0];
        yId = document.focusCoordinates[1];
    }

    let foundPlanet = null;
    if (props.isRequested) {
        for (let planet of planets) {
            if ( Number(xId) === Number(planet.position.x) && +yId === +planet.position.y) {
                foundPlanet = planet;
                break;
            }
        }
        if (foundPlanet && !isOpen) {
            setBody("details");
            onOpen();
        }
    }   
    
    let title =null;
    let missionActive = false;
    let placesActive = false;
    if (foundPlanet) {
        title = foundPlanet.name;
        if (foundPlanet.mission) {
            missionActive = true;
        }
        if (foundPlanet.places && foundPlanet.places.length > 0) {
            placesActive = true;
        }
        
    }


    const fullWidth = 1101;
    const fullHeight = 600;
    const planetWidth = 351;
    const mainHeight = 312;
    const descWidth = 726;
    const descGap = 8;
    const subtitleWidth = 200;
    const subtitleHeight = 60;
    const gridGap = 24;

    let body;
    switch (bodyType){
        case "details":
            body = <PlanetDetails
                isRequested = {props.isRequested}
                fullWidth = {fullWidth}
                fullHeight = {fullHeight}
                planetWidth = {planetWidth}
                mainHeight = {mainHeight}
                descWidth = {descWidth}
                descGap = {descGap}
                subtitleWidth = {subtitleWidth}
                subtitleHeight = {subtitleHeight}
                gridGap = {gridGap}
            ></PlanetDetails>
            break;
        case "mission":
            body = <MissionDetails
                isRequested = {props.isRequested}
                fullWidth = {fullWidth}
                fullHeight = {fullHeight}
            ></MissionDetails>
            break;
        case "places":
            body = <PlaceDetails
                isRequested = {props.isRequested}
                fullWidth = {fullWidth}
                fullHeight = {fullHeight}
                gridGap = {gridGap}
            ></PlaceDetails>
            break;
        default:
            console.log("Invalid bodyType: " + bodyType)
    }

    return (
        <div>
            <Modal isOpen={isOpen} onClose={ () => 
                {
                    onClose(); 
                    props.endRequest()
                }
            }
            isCentered>
                <ModalOverlay />
                <ModalContent maxWidth={fullWidth + gridGap*2} backgroundColor="rgba(34, 41, 42,0.4)" border="1px solid rgba(240, 248, 255, 0.3)">
                    <ModalHeader> 
                        <PlanetHeader
                            height = {70}
                            width = {fullWidth}
                            title = {title}
                            missionAvailable = {missionActive}
                            placesActive = {placesActive}
                            selectBody={setBody}></PlanetHeader>
                    </ModalHeader>
                    <ModalCloseButton color="white" size="lg" />
                    <ModalBody paddingBottom="16px" >
                        {body}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    );
}