import React, {useState, useRef, useEffect} from 'react';
import {MenuButton} from '../Utils.js'
import { 
    Grid,
    useDisclosure,
} from "@chakra-ui/react"
import {missions} from '../../Data.js';


export function MissionList(props) {
    const { onOpen } = useDisclosure()
    const [ width, setWidth ] = useState(0);
    const ref = useRef(null);
    useEffect(() => {
        setWidth(ref.current ? ref.current.offsetWidth : 0);
    }, [ref.current]);
    const contentWidth = width-30;

    let buttons = missions.map( (p_mission, i) => {       
        return (
            <MenuButton key={"missionButton"+i} height={70} width={contentWidth} state={""} setActive={() => {
                onOpen();
                props.setMission(p_mission);
            }}
            id={i} active={false} text={p_mission.shortName} className="text-mobile-button"></MenuButton>
        );
        }   
    );

        return (
            <div ref={ref}>
                <Grid 
                    gap="10px"
                    padding="15px"
                >
                    {buttons}
                </Grid>
            </div>
        )
}