import React, {useState} from 'react';
import { 
    BackgroundButton,
} from './Backgrounds.js';

export function MenuButton(props) {
    const [state, changeState] = useState("");
    const positionText=[20,20];
    const className = props.className ? props.className : "Text-Standard";

    return (
        <div className="text-container" 
            style={{
                height: props.height, 
                width: props.width,
                cursor: props.active ? "" : "pointer"
            }}
            onMouseOver={()=> changeState("hovered")} 
            onMouseLeave={()=> changeState("")}
            onMouseDown={()=> {
            changeState("pressed"); 
            }}
            onMouseUp={()=> {
                changeState("hovered");
                if (!props.active) {
                    props.setActive(props.id);
                }
            }
        }>
            <svg height={props.height} width={props.width} className="shadow-light" >
                <g>
                    <BackgroundButton height={props.height} width={props.width} state={state} active={props.active}></BackgroundButton>
                </g>
            </svg>
            <div style={{left: positionText[0]}} className={className + " text-centered-vertical"}>{props.text}</div>
        </div>
    )
}