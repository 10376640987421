// Secrecy levels:
// Intern:          Information is not protected within prometheus. Mission details can be shared freely.
// Need to know:    Information is not to be shared with unrelated personnel. Still at agent discretion.
// Secret:          Details are not to be shared with personnel not authorized by superiors.
// Top Secret:      The existence of the mission is secret. Agent affiliation to prometheus may be required to be kept secret. 

export const missions = [
    {
        planet: {
            x: 6,
            y: 5,
        },
        shortName: ["Dr. Takahashis Prototyp"],
        codename: "Zagreus",
        classis: "Zaphkiel",
        site: "Dahilah",
        secrecy: "Secret",
        danger: "Unbekannt",
        reward: "8000 Credits",
        primaryObj: "- Dr. Takahashis gestohlenen Prototyp wiedererlangen",
        secondaryObj: "- Dr. Takahashis Mörder finden\n\n"+
            "- Mörder festnehmen oder neutralisieren",
        additionalInfo: ["[Classified]"
            ],
        support: "- Nach Absprache",
    },
    {
        planet: {
            x: 5,
            y: 8,
        },
        shortName: ["Wirtschaftsspionage (Leonidas Corp.)"],
        codename: "Khamael.123",
        classis: "Khamael",
        site: "Dahilah",
        secrecy: "Secret",
        danger: "Medium",
        reward: "4000 Credits",
        primaryObj: "- Leonidas Fabrik zur Herstellung von [Classified] finden",
        secondaryObj:  "- Informationen zur Sicherheit zusammentragen\n\n"
        + "- Informationen zur Versorgungskette zusammentragen",
        additionalInfo: "- Leonidas Corp. hat eine sehr negative Einstellung gegenüber Prometheus\n\n"
        + "- \"Gefundene\" Ausrüstung darf behalten werden. (Ausnahme: [Classified])",
        support: "- Safehouse auf Lalgola",
    },
    {
        planet: {
            x: 8,
            y: 7,
        },
        shortName: ["Spionage (Saratov)"],
        codename: "Supernova",
        classis: "Zaphkiel",
        site: "Gangut [Wrack]",
        secrecy: "Need to Know",
        danger: "Hoch",
        reward: "6000 Credits",
        primaryObj: "- Absturzstelle untersuchen",
        secondaryObj: "- Proben von Interesse nehmen",
        additionalInfo: ["TBA"
            ],
        support: "- Moderater Support vorhanden",
    },
    
]

export const archivedMissions = [
    {
        planet: {
            x: 4,
            y: 6,
        },
        shortName: ["Psychic-affine Pflanze"],
        codename: "Zaphkiel.324",
        classis: "Zaphkiel",
        site: "FoB Chandragiri",
        secrecy: "Intern",
        danger: "Gering",
        reward: "1500 Credits",
        primaryObj: "- Existenz einer psychic-affinenen Pflanze überprüfen ",
        secondaryObj: 
            "- Eigenschaften der psychic-affinen Pflanze ermitteln\n\n"+
            "- Probe nehmen",
        additionalInfo: "- Ansprechpartner: Sanjay Trivedi\n\n"+
            "- Validität der Informationen sehr unsicher\n\n"+
            "Vorsicht: Psychicverfolgung auf Villivakkam",
        support: "- Squad K7-463",
        resolution: "- Proben nach Eletsina gebracht\n\n"+ 
            "- Scheint Radioaktivität in nutzbare Energie umwandeln zu können"
    },
    {
        planet: {
            x: 6,
            y: 5,
        },
        shortName: "Doktor Takahashi Hiroya",
        codename: "Zaphkiel.327",
        classis: "Zaphkiel",
        site: "Baqubah",
        secrecy: "Need to know",
        danger: "Gering",
        reward: "1500 Credits",
        primaryObj: "- Status von Dr. Takahashi Hiroya überprüfen",
        secondaryObj: "- Dr. Takahashi nach Eletsina bringen\n\n"+
            " oder \n\n"+
            "- Nachricht von Dr. Takahashi nach Eletsina bringen",
        additionalInfo: "- Letzter bekannter Standort:\n"+ 
            "38°04'53.4\"N 46°17'32.5\"E",
        support: "Keine",
        resolution: "- Dr. Takahashi ermordet auf Sana aufgefunden\n\n" + 
            "- Getötet in einem alten Tempel der Schlafenden\n\n" +
            "- Mordwaffe vermutlich Zeremoniedolch der Orden der Schlafenden",
    },
]

const personRegisterInfo = [
    {
        name: "Sanjay Trivedi",
        homeplanet: {
            position: {
                x: 4,
                y: 6
            }
        },
        work: "Logistiker",
        organization: "Prometheus\n"+
            "Classis Arakieli\n\n"+
            "On-Site Manager",
        additionalInfo: "Leitet den Standort in Grahisa"
    },
    {
        name: "Dr. Takahashi Hiroya [Tod]",
        homeplanet: {
            name: "[Classified]"
        },
        work: "Forscher",
        organization: "Prometheus\nClassis Zaphkieli\n\n[Classified]",
        additionalInfo: "Ermordet auf Sana\n\n"+
            "[Classified]"
    },
    {
        name: "Shiksk",
        homeplanet: {
            position: {
                x: 4,
                y: 6
            }
        },
        work: "Psychic-Mentor",
        organization: "Unabhängig",
        additionalInfo: "- Bekannte Disziplinen: \n"+ 
        "    - Biopsionics\n"+
        "    - Precognition\n"+
        "    - Metapsionics"
    },
    {
        name: "Nazir Isa",
        homeplanet: {
            position: {
                x: 6,
                y: 5,
            }
        },
        work: "Student",
        organization: "Isa-Clan\nNeffe des Clanobervertreter in Dahilah",
        additionalInfo: "Befreundet mit Cafran Kolea"
    },
    {
        name: "Cafran Kolea",
        homeplanet: {
            position: {
                x: 5,
                y: 8
            }
        },
        work: "Informant",
        organization: "Prometheus\n"+
            "Classis Zaphkieli\n"+
            "= AIPA =\n\n"+
            "([A]bteilung für [I]nformations- und [P]roben-[A]ggregation)",
        additionalInfo: "- War vor der Rekrutierung in AIPA auf Saratov aktiv"
    },
    {
        name: "Murakami Kiko",
        homeplanet: {
            position: {
                x: 7,
                y: 2
            }
        },
        work: "Mechanikerin",
        organization: "Prometheus\n"+"Classis Khamaeli\n"+"= AIPA =\n"+"Seargent\n\n"+
            "([A]bteilung für [I]nformations- und [P]roben-[A]ggregation)",
        additionalInfo: "- Besitzt die typischen Moriya-Implantate"
    },
    {
        name: "Athelia Makris",
        homeplanet: {
            position: {
                x: 5,
                y: 8
            }
        },
        work: "Forscherin",
        organization: "Prometheus\nClassis Zaphkieli\n= AIPA =\n\n"+
        "([A]bteilung für [I]nformations- und [P]roben-[A]ggregation)",
        additionalInfo: "- Schwerpunkt Botanik\n\n"+ 
            "- Doktorarbeit:\n"+ 
            "Theoretischer Beweis für die mögliche Existenz von psychic-affinen Pflanzen.\n"+
            "Der Arbeit nach ist es überraschender, dass bisher noch keine gefunden wurden."
        
    },
    {
        name: "Zubair Essa",
        homeplanet: {
            position: {
                x: 6,
                y: 5,
            }
        },
        work: "Ex-Kundschaftler",
        organization: "Unabhängig",
        additionalInfo: "- Befreundet mit Nazir Isa\n\n"+ 
            "- Besitzt Sand-Surfer"
        
    },
]

const planetInformation = [
    {
        position: {
            x: 1,
            y: 3,
        },
        name: "Lushun",
        previewImage: "Images/Preview/Unknown150.png",
        image: "Images/Preview/Unknown250.png",
        description: ["???"],
        atmo: "???",
        prometheus: "???",
        population: "???",
        technology: "???",
    },
    {
        position: {
            x: 1,
            y: 4,
        },
        name: "Jhinzhou",
        previewImage: "Images/Preview/Unknown150.png",
        image: "Images/Preview/Unknown250.png",
        description: ["???"],
        atmo: "???",
        prometheus: "???",
        population: "???",
        technology: "???",
    },
    /*
    {
        position: {
            x: 2,
            y: 8,
        },
        name: "Guwahati",
        previewImage: "Images/Preview/Unknown150.png",
        image: "Images/Preview/Unknown250.png",
        description: ["???"],
        atmo: "???",
        prometheus: "???",
        population: "???",
        technology: "???",
    },*/
    {
        position: {
            x: 3,
            y: 2,
        },
        name: "Taiyuan",
        previewImage: "Images/Preview/Unknown150.png",
        image: "Images/Preview/Unknown250.png",
        description: ["???"],
        atmo: "???",
        prometheus: "???",
        population: "???",
        technology: "???",
    },
    {
        position: {
            x: 4,
            y: 6,
        },
        name: "Villivakkam",
        previewImage: "Images/Preview/Villivakkam150.png",
        image: "Images/Preview/Villivakkam250.png",
        description: "- Psychics werden verachtet und gejagt\n\n"+
        "- Erhebliche Hintergrund-Radioaktivität",
        atmo: "kalt - atembar",
        prometheus: "Geringes Interesse\nKeine Präsenz",
        population: "658 Millionen",
        technology: "TL4 (Postech)",
        places: [  
            {
                name: "Villivakkam City",
                notes: "Hauptstadt"
            },
            {
                name: "Chandragiri",
                notes: "Kleine Stadt\n~75k Einwohner"
            },
            {
                name: "FOB Chandragiri",
                notes: "Prometheus Basis in Grahisa (Forward Operating Base)"
            },
            {
                name: "Grahisa",
                notes: "Vorort von Chandragiri"
            },
        ]
    },
    /*
    {
        position: {
            x: 4,
            y: 7,
        },
        name: "Panipat",
        previewImage: "Images/Preview/Unknown150.png",
        image: "Images/Preview/Unknown250.png",
        description: ["???"],
        atmo: "???",
        prometheus: "???",
        population: "???",
        technology: "???",
    },*/
    /*
    {
        position: {
            x: 4,
            y: 8,
        },
        name: "Balarika",
        previewImage: "Images/Preview/Unknown150.png",
        image: "Images/Preview/Unknown250.png",
        description: ["???"],
        atmo: "???",
        prometheus: "???",
        population: "???",
        technology: "???",
    },*/
    {
        position: {
            x: 5,
            y: 4,
        },
        name: "Misr",
        previewImage: "Images/Preview/Misr150.png",
        image: "Images/Preview/Misr250.png",
        description: "- Leben in Stadtdomen\n\n"+
            "- Unsterbliche Aliens plagen die Zivilisation\n\n"+
            "- Pretech Türme",
        atmo: "kalt - atembar",
        prometheus: "moderates Interesse\nmoderate Präsenz",
        population: "40 Millionen",
        technology: "TL4 (Postech)",
    },
    {
        position: {
            x: 5,
            y: 8,
        },
        name: "Lalgola",
        previewImage: "Images/Preview/Lalgola150.png",
        image: "Images/Preview/Lalgola250.png",
        description: "- Von Mega-Corps beherrscht\n\n"+
            "- Cyberware-Hochburg",
        atmo: "warm - atembar",
        prometheus: "Moderates Interesse\nGeringe Präsenz",
        population: "88 Millionen",
        technology: "TL4 (Postech)",
    },
    {
        position: {
            x: 6,
            y: 5,
        },
        name: "Sana",
        previewImage: "Images/Preview/Sana150.png",
        image: "Images/Preview/Sana250.png",
        description: "- Wüstenplanet\n\n"+
        "- Pretech Stadt-Ruinen",
        atmo: "angenehm - atembar",
        prometheus: "Kein Interesse\nKeine Präsenz",
        population: "628 Millionen",
        technology: "TL3 (Moderne)",
        places: [
            {
                name: "Baqubah",
                notes: "- Handelsstadt von Sana\n\n"+
                    "- Einziger Hafen des Planeten"
            },
            {
                name: "Dahilah",
                notes: "- Stadt, kontrolliert von dem Isa Clan\n\n"+
                    "- In der Nähe von einem Tempel der Schlafenden"
            }
        ]
    },
    {
        position: {
            x: 6,
            y: 6,
        },
        name: "Lacant",
        previewImage: "Images/Preview/Lacant150.png",
        image: "Images/Preview/Lacant250.png",
        description: "- Leben in Himmelsstädten\n\n"+
            "- Giftige Oberfläche\n\n"+
            "- Psychic-Akademie",
        atmo: "angenehm - atembar",
        prometheus: "moderates Interesse\nmoderate Präsenz",
        population: "7 Milliarden",
        technology: "TL4 (Postech)",
    },
    {
        position: {
            x: 6,
            y: 7,
        },
        name: "Eletsina",
        previewImage: "Images/Preview/Eletsina150.png",
        image: "Images/Preview/Eletsina250.png",
        description: "- Bekannt für Söldnertum\n\n"+
        "- Viele Flüchtlinge aus dem aktuellen Konflikt finden hier Zuflucht",
        atmo: "kalt\nAtemmaske notwendig",
        prometheus: "Hochburg",
        population: "75 Millionen",
        technology: "TL4 (Postech)",
    },
    {
        position: {
            x: 6,
            y: 8,
        },
        name: "Erymanthos",
        previewImage: "Images/Preview/Erymanthos150.png",
        image: "Images/Preview/Erymanthos250.png",
        description: "- Informationsagentur (Perimeter Agency)\n\n" + "- besetzt (Stahlschlangen)",
        atmo: "angenehm - atembar",
        prometheus: "Moderates Interesse\nHohe Präsenz",
        population: "92 Tausend",
        technology: "TL4 (Postech)",
    },
    {
        position: {
            x: 7,
            y: 2,
        },
        name: "Moriya",
        previewImage: "Images/Preview/Moriya150.png",
        image: "Images/Preview/Moriya250.png",
        description: "- Abgeschottet",
        atmo: "n/a - atembar",
        prometheus: "moderates Interesse\nmoderate Präsenz",
        population: "17 Millionen",
        technology: "TL4 (Postech)",
    },
    /*
    {
        position: {
            x: 7,
            y: 5,
        },
        name: "Yaman",
        previewImage: "Images/Preview/Unknown150.png",
        image: "Images/Preview/Unknown250.png",
        description: ["???"],
        atmo: "???",
        prometheus: "???",
        population: "???",
        technology: "???",
    },*/
    {
        position: {
            x: 7,
            y: 8,
        },
        name: "Voronezh",
        previewImage: "Images/Preview/Voronezh150.png",
        image: "Images/Preview/Voronezh250.png",
        description: "- Besetzt",
        atmo: "warm - atembar",
        prometheus: "moderates Interesse\ngeringe Präsenz",
        population: "54 Millionen",
        technology: "TL3 (Moderne)",
    },
    /*
    {
        position: {
            x: 8,
            y: 3,
        },
        name: "Yuki",
        previewImage: "Images/Preview/Unknown150.png",
        image: "Images/Preview/Unknown250.png",
        description: ["???"],
        atmo: "???",
        prometheus: "???",
        population: "???",
        technology: "???",
    },*/
    {
        position: {
            x: 8,
            y: 7,
        },
        name: "Saratov",
        previewImage: "Images/Preview/Saratov150.png",
        image: "Images/Preview/Saratov250.png",
        description: "- Bis vor kurzem aktives Kriegsgebiet\n\n"+
            "- Radioaktive Rückstände des Krieges",
        atmo: "Warm\nAtembar - Radioaktiv",
        prometheus: "Hohes Interesse\nModerate Präsenz",
        population: "50 Millionen",
        technology: "TL4 (Postech)",
    },
    {
        position: {
            x: 8,
            y: 8,
        },
        name: "Us-tauhts",
        previewImage: "Images/Preview/Moscow150.png",
        image: "Images/Preview/Moscow250.png",
        description: "- Heimat der Stahlschlangen",
        atmo: "kalt - nicht atembar",
        prometheus: "Hohes Interesse\nKeine Präsenz",
        population: "???",
        technology: "???",
    },
    /*
    {
        position: {
            x: 9,
            y: 1,
        },
        name: "Kamisu",
        previewImage: "Images/Preview/Unknown150.png",
        image: "Images/Preview/Unknown250.png",
        description: ["???"],
        atmo: "???",
        prometheus: "???",
        population: "???",
        technology: "???",
    },*/
    
    {
        position: {
            x: 9,
            y: 5,
        },
        name: "Gajuko",
        previewImage: "Images/Preview/Unknown150.png",
        image: "Images/Preview/Unknown250.png",
        description: ["???"],
        atmo: "???",
        prometheus: "???",
        population: "???",
        technology: "???",
    },
    /*
    {
        position: {
            x: 10,
            y: 2,
        },
        name: "Ryugasaki",
        previewImage: "Images/Preview/Unknown150.png",
        image: "Images/Preview/Unknown250.png",
        description: ["???"],
        atmo: "???",
        prometheus: "???",
        population: "???",
        technology: "???",
    },*/
    
]

export let planets = [];

for (let planet of planetInformation) {
    let foundMission = null;
    for (let mission of missions) {
        if (mission.planet.x === planet.position.x && mission.planet.y === planet.position.y) {
            foundMission = mission;
            break;
        }
    }
    let missPlanet = Object.assign({}, planet);
    missPlanet.mission =foundMission;
    planets.push(missPlanet);
}

export let personRegister = [];
for (let person of personRegisterInfo) {
    let planetName = null;
    for (let planet of planets) {
        if (person.homeplanet.position) {
            if (person.homeplanet.position.x === planet.position.x && person.homeplanet.position.y === planet.position.y) {
                planetName = planet.name;
                break;
            }
        }
    }
    let persWithPlanet = Object.assign({}, person);
    if (planetName) {
        persWithPlanet.homeplanet.name = planetName;
    }
    personRegister.push(persWithPlanet);
}

personRegister.sort((pers1, pers2) => pers1.name.localeCompare(pers2.name))