import React from 'react';
import {planets} from '../../Data.js';
import {PlanetPreview} from "./PlanetPreview.js";
import {Planet} from "./Planet.js";
import {MissionIcon} from './MissionIcon.js';

const NewEnterEvent = new Event('hexEnter');
export class Hexagon extends React.Component {

  constructor(props) {
    super(props);
    const smallRad = this.props.smallDia/2;
    const bigRad = smallRad/ Math.cos(Math.PI/6);
    const position = [props.posX, props.posY];
    // Hexagon points
    const relHexPoints = [
      [0,bigRad/2],
      [smallRad, 0],
      [smallRad*2, bigRad/2],
      [smallRad*2, bigRad*3/2],
      [smallRad, bigRad*2],
      [0, bigRad*3/2]  
    ];
    const hexPoints = relHexPoints.map( (x)=> (Number(position[0]) + x[0]) + " " +(Number(position[1]) + x[1]));
    const stringHexPoints = hexPoints.join(",");
    
    const relSquarePoints = [
      [-smallRad,-smallRad],
      [smallRad, -smallRad],
      [smallRad*3, -smallRad],
      [smallRad*3, bigRad*3],
      [smallRad, bigRad*3],
      [-smallRad, bigRad*3]  
    ];
    const sqPoints = relSquarePoints.map( (x)=> (Number(position[0]) + x[0]) + " " +(Number(position[1]) + x[1]));
    const stringSqPoints = sqPoints.join(",");
    

    this.state= {
      position: position,
      hexPoints: stringHexPoints,
      sqPoints: stringSqPoints,
      smallDia:Number(props.smallDia),
      xId: props.xId,
      yId: props.yId,
      inFocus: false
    };
  }

  isPlanet() {
    for (let planet of planets) {
      if ( Number(this.state.xId) === Number(planet.position.x) && +this.state.yId === +planet.position.y) {
        return true;
      }
    }
    return false;
  }

  handleEvent(event) {
    switch(event.type) {
      case "mouseover": {
        this.handleMouseOver();
        break;
      }
      case "hexEnter": {
        this.handleMouseLeave();
        break;
      }
      case "mousedown": {
        this.handleMouseClick();
        break;
      }
      default: {
        console.log(event.type);

      }
    }
  }

  handleMouseOver() {
    const polyId="polygon" + this.state.xId + "-" +this.state.yId;
    const newPolyAttr = "#"+polyId;
    const polyUseElement = document.getElementById("focusHex");

    const previewId="preview" + this.state.xId + "-" +this.state.yId;
    const newPreviewAttr = "#"+ previewId;
    const previewUseElement = document.getElementById("focusPreview");
    previewUseElement.setAttribute("href", newPreviewAttr );

    // set focus variable
    document.focusCoordinates = [this.state.xId, this.state.yId]


    if ( this.isPlanet()) {
      polyUseElement.setAttribute("href", newPolyAttr );

      this.setState({inFocus: true});


      const AnimateId="animateExpand" + this.state.xId + "-" +this.state.yId;
      const animateElement = document.getElementById(AnimateId);
      animateElement.beginElement();
  
      // Send Event
      document.dispatchEvent(NewEnterEvent);
      document.addEventListener("hexEnter", this);

    }
    else {
      polyUseElement.setAttribute("href", "" );
      document.dispatchEvent(NewEnterEvent);
    }
  } 
  handleMouseClick() {
    console.log("been clicked!");
  }

  handleMouseLeave() {
    this.setState({inFocus: false});
    const AnimateId="animateShrink" + this.state.xId + "-" +this.state.yId;
    const animateElement = document.getElementById(AnimateId);
    animateElement.beginElement();
    document.removeEventListener("hexEnter", this);
  }

  getCenter() {
    const smallRad = this.state.smallDia/2;
    const bigRad = smallRad/ Math.cos(Math.PI/6);
    const x = this.state.position[0] + smallRad;
    const y = this.state.position[1] + bigRad;
    return [x,y];
  }

  render() {
    const expandId="animateExpand" + this.state.xId + "-" +this.state.yId;
    const resetId="animateShrink" + this.state.xId + "-" +this.state.yId;
    const polyId="polygon" + this.state.xId + "-" +this.state.yId;
    const center = this.getCenter();
    return (
        <g onClick={() => this.handleMouseClick()}>
          <PlanetPreview position={this.state.position} smallDia={this.state.smallDia} xId = {this.state.xId} yId = {this.state.yId} inFocus={this.state.inFocus}/>
          <polygon id={polyId} className="hexagon" points={this.state.hexPoints} 
          style={{cursor: this.state.inFocus ? "pointer" : ""}}
          stroke="grey" fill="black" 
          onMouseEnter={() => this.handleMouseOver()} 
          >
            <animate id={expandId} attributeName="points" to={this.state.sqPoints} dur="150ms" begin="indefinite" fill="freeze" />
            <animate id={resetId} attributeName="points" to={this.state.hexPoints} dur="150ms" begin="indefinite" fill="freeze" />
          </polygon>
          <Planet position={center} smallImage={true} diameter={this.state.smallDia/2} xId = {this.state.xId} yId = {this.state.yId}/>
          <MissionIcon xId={this.state.xId} yId={this.state.yId} x={this.state.position[0] + this.state.smallDia/2 } y={this.state.position[1] + this.state.smallDia/4 -3} size="25px" alt="Mission available" />
        </g>
        
    )
  }
}