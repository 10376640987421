import React, {useState} from 'react';
import {MenuButton} from '../Utils.js'
import { 
    Grid,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react"
import {archivedMissions} from '../../Data.js';
import {MissionDetails} from '../SectorOverview/MissionDetails.js';
import {BackgroundTitle} from '../Backgrounds.js';

function MissionHeader(props) {
    return (
        <div className="text-container shadow-light" style={{height: props.height}}>
            <svg zIndex="-1" style={{position:"absolute"}}  width={props.width} height={props.height}>
                <BackgroundTitle height={props.height} width={props.width}/>
            </svg>
            <div style={{left: props.height+10}} className="text-centered-vertical Text-Standard Text-MissionHeader">{props.text}</div>
        </div>
    );
}

export function ArchiveScreen(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [ mission, setMission ] = useState({});
    let buttons = archivedMissions.map( (p_mission, i) => {       
        return (
            <MenuButton key={"missionButton"+i} height={70} width={900} state={""} setActive={() => {
                onOpen();
                setMission(p_mission);
            }}
            id={i} active={false} text={p_mission.shortName}></MenuButton>
        );
        }   
    );

    const fullWidth = 1101;
    const fullHeight = 700; 
    const gridGap = 24;


    if (props.enabled) {
        return (
            <div>
                <Grid 
                    gap="10px"
                    padding="15px"
                    margin="10px"
                >
                    {buttons}
                </Grid>
                <Modal isOpen={isOpen} onClose={ () => 
                    {
                        onClose();
                    }
                }
                isCentered>
                    <ModalOverlay />
                    <ModalContent maxWidth={fullWidth + gridGap*2} backgroundColor="rgba(37, 40, 41,0.95)" border="1px solid rgba(240, 248, 255, 0.3)">
                        <ModalHeader> 
                            <MissionHeader
                                height = {70}
                                width = {fullWidth - 40}
                                text = {mission.shortName}></MissionHeader>
                        </ModalHeader>
                        <ModalCloseButton color="white" size="lg" />
                        <ModalBody paddingBottom="16px">
                            <MissionDetails
                                showResolution = {true} 
                                fullWidth = {fullWidth}
                                fullHeight = {fullHeight}
                                mission = {mission}
                            ></MissionDetails>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </div>
        )
    }
    return null;
}