import React, {useState} from 'react';
import Typing from 'react-typing-animation';
import { 
    Grid,
    useDisclosure,
    SlideFade,
} from "@chakra-ui/react"
import {MenuButton} from '../Utils.js'

function WelcomeIcon(props) {
    return (
        <div className="shadow-medium" 
        >
            <div width="383" height="383" >
                <img
                src="Prometheus512.png" alt="PrometheusIcon" width="383" height="383" className="center"></img>
            </div>
            <div style={{marginTop: "20px"}}>
                <Typing
                    onFinishedTyping={() => props.onFinishedTyping()}
                >
                    <span className="Text-Welcome">Welcome back, Agent</span>
                </Typing>
            </div>

        </div>
    )
}

function Menu(props) {
    return (
        <Grid 
            backgroundColor="rgba(34, 41, 42,0.4)" 
            border="1px solid rgba(240, 248, 255, 0.3)"
            borderRadius="10px"
            templateRows={"100px 100px 100px" }
            gap="10px"
            padding="15px"
            className="shadow-light MainMenu-buttonfield" 
        >
            <MenuButton className="Text-MenuButton" height={100} width={550} state={""} setActive={props.onClick} id={1} active={props.activeId===1} text="Sektorübersicht"></MenuButton>
            <MenuButton className="Text-MenuButton" height={100} width={550} state={""} setActive={props.onClick} id={2} active={props.activeId===2} text="Missions-Archiv"></MenuButton>
            <MenuButton className="Text-MenuButton" height={100} width={550} state={""} setActive={props.onClick} id={3} active={props.activeId===3} text="Personenregister"></MenuButton>
        </Grid>
    )
}

export function WelcomeScreen(props) {
    const { isOpen, onToggle } = useDisclosure()
    const [ activeId, setActiveId ] = useState(props.selectedId)
    const [ hasAnimationClass, setAnimationClass ] = useState(false)

    let className = "";
    if(hasAnimationClass) {
        className = "MainMenu-MoveAnimation";
    }

    let setSelectedId = (id) => {
        setAnimationClass(true);
        setActiveId(id);
        props.setSelectedId(id);
    }

    return (
        <div className={"MainMenu-body " + className} >
            <WelcomeIcon onFinishedTyping={() => {
                setTimeout(function() {
                    onToggle();
                }, 500);
            }}></WelcomeIcon>
            <SlideFade in={isOpen} offsetY="20px">
                <Menu onClick={setSelectedId} activeId={activeId}></Menu>
            </SlideFade>
        </div>
    )
}