import React from 'react';

export function BackgroundTitle(props) {
    const height = props.height;
    const width = props.width;

    const goldRatio = (1+Math.sqrt(5))/2
    const smallLength = height/goldRatio;
    const smallCutLength = smallLength/goldRatio;



    const startPoints = [
        [smallCutLength, 0],
        [ smallLength, 0],
        [0, smallLength],
        [0, smallCutLength]
    ]
    const endPoints = [
        [width, height - smallLength],
        [width, height - smallCutLength],
        [width - smallCutLength, height],
        [width - smallLength, height]

    ]
    const mainPoints = [
        [height,0],
        [width, 0],
        [width - height, height],
        [0, height]
        ];

    return (
        <g>
            <polygon className="title-back" points={startPoints} 
            stroke="grey" fill="#394446" opacity="0.9"></polygon>
            <polygon className="title-back" points={mainPoints} 
            stroke="grey" fill="#394446" opacity="0.9"></polygon>
            <polygon className="title-back" points={endPoints}  
            stroke="grey" fill="#394446" opacity="0.9"></polygon>
        </g>
    );
}

export function BackgroundGeneric(props) {
    const cutLength = props.cutLength ? props.cutLength : 20;
    const offsetX = props.positionX ? props.positionX : 0;
    const offsetY = props.positionY ? props.positionY : 0;
          
    const height = props.height;
    const width = props.width;

    const points = [
        [cutLength + offsetX,offsetY],
        [width - cutLength + offsetX, offsetY],
        [width + offsetX, cutLength + offsetY],
        [width + offsetX, height - cutLength + offsetY],
        [width - cutLength + offsetX, height + offsetY],
        [cutLength + offsetX, height + offsetY],
        [offsetX, height - cutLength + offsetY],
        [offsetX, cutLength + offsetY]  
      ];

    return (
        <polygon className="octagon" points={points} 
        stroke="grey" fill="#394446" opacity="0.9"></polygon>
    );
}

export function BackgroundButton(props) {
    const cutLength = 20;

    let color;
    switch (props.state) {
        case "hovered": {
            color ="rgb(45,60,62)"
            break;
        }
        case "pressed": {
            // lighter:
            // color ="rgb(54,76,79)"
            
            // darker:
            color ="rgb(31,53,56)"
            break;
        }
        default: {
            color ="#394446"
            break;
        }
    }

    let borderWidth = 1;
    if (props.active) {
        color ="rgb(31,53,56)"
        borderWidth = 4;
    }

          
    const height = props.height - borderWidth/2;
    const width = props.width - borderWidth/2;

    const points = [
        [cutLength, borderWidth/2],
        [width - cutLength,  borderWidth/2],
        [width, cutLength],
        [width , height - cutLength],
        [width - cutLength, height],
        [cutLength, height],
        [borderWidth/2, height - cutLength],
        [borderWidth/2, cutLength]  
      ];

    return (
        <polygon className="octagon" points={points} 
        stroke="grey" strokeWidth={borderWidth} fill={color} opacity="0.9"></polygon>
    );
}

export function BackgroundAttributeTitle(props) {
    const cutLength = 20;
          
    const height = props.height;
    const width = props.width;
    const cutLenLarge = height/2;
    const points = [
        [cutLength,0],
        [width - cutLenLarge, 0],
        [width , cutLenLarge],
        [width - cutLenLarge, height],
        [cutLength, height],
        [0, height - cutLength],
        [0, cutLength]  
      ];

    return (
        <polygon className="octagon" points={points} 
        stroke="grey" fill="#394446" opacity="0.9"></polygon>
    );
}

export function BackgroundAttributeValue(props) {
    const cutLength = 20;
          
    const height = props.height;
    const width = props.width;
    const cutLenLarge = height/2;
    const points = [
        [props.offset - cutLenLarge,0],
        [props.offset + width - cutLength, 0],
        [props.offset + width , cutLength],
        [props.offset + width , height - cutLength],
        [props.offset + width - cutLength, height],
        [props.offset - cutLenLarge, height],
        [props.offset + 0, height - cutLenLarge],
      ];

    return (
        <polygon className="octagon" points={points} 
        stroke="grey" fill="#394446" opacity="0.9"></polygon>
    );
}