import React from 'react';
import {Hexagon} from "./Hexagon.js";

export function HexRow(props) {
    const rowArr = Array(Number(props.number)).fill(null);
    const row = rowArr.map( (hex, i) => {
        const actualI = i+1;
        const horizGap = 6
        const offset = props.yId%2 === 0 ? (Number(props.smallDia) + horizGap)/2 : 0;
        let key = "hex " + props.yId + " " + actualI; 
        return (
        <Hexagon key={key} xId={actualI} yId={props.yId} posX={i*(Number(props.smallDia)+6) + offset + Number(props.smallDia/2) +1} posY={props.posY} smallDia={props.smallDia}></Hexagon>);
      }
    );
    return(
      <g length="auto" width="auto" height="auto">
        {row}
      </g>
    )
  }