import React from 'react';
import { 
    MenuButton} from "../Utils.js"
import { 
    BackgroundGeneric,
} from '../Backgrounds.js';
import {MissionHeader} from './MissionDetails.js';
import Typing from 'react-typing-animation';


function MissionDescriptionText(props) {
    const positionText = [20, 20];

    return (
        <div className="text-container" style={{height: props.height+20, width: props.width-40}}>
            <svg height={props.height} width={props.width} className="shadow-light">
                <BackgroundGeneric height={props.height} width={props.width}></BackgroundGeneric>
            </svg>
            <div style={{left: positionText[0]}} className={"text-mobile-standard text-top"}>
                <Typing speed="1">
                    {props.text}
                </Typing>
            </div>
        </div>
    )
}


export function MissionText(props) {
   
    return (
        
        <div>
            <MissionHeader
                height = {50}
                width = {props.fullWidth}
                text = {props.title}>
            </MissionHeader>
            <div style={{padding: "15px"}}>
                <MissionDescriptionText height={400} width={props.fullWidth-30} text={props.text}></MissionDescriptionText>
            </div>
            <div>
                <MenuButton height={70} width={props.fullWidth} text={["Zurück"]} setActive={props.backFunction} active={false} id={4}></MenuButton>
            </div>
        </div>
    );
}
