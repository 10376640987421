import React, {useState} from 'react';
import {
    Grid,
    GridItem,
    Tooltip
} from "@chakra-ui/react"
import { 
    BackgroundButton,
} from '../Backgrounds.js';
import { 
    DetailsTitle, 
} from './DetailParts.js';

function PlanetHeaderButton(props) {
    const [state, changeState] = useState("");

    
    let mouseOver = null;
    let mouseLeave = null;
    let mouseDown = null;
    let mouseUp = null;
    
    if (!props.disabled) {
        mouseOver=()=> changeState("hovered")
        mouseLeave=()=> changeState("")
        mouseDown=()=> {
            changeState("pressed"); 
        }
        mouseUp=()=> {
            changeState("hovered");
            props.setActive();
        }
    }

    return (
        <div style={{
                cursor: (props.active || props.disabled) ? "" : "pointer",
                filter: props.disabled ? "grayscale(80%)" : ""
            }} 
            onMouseOver={mouseOver} 
            onMouseLeave={mouseLeave}
            onMouseDown={mouseDown}
            onMouseUp={mouseUp} >
            <Tooltip label={props.alt}>
                <svg height={props.size} width={props.size}>
                    <g >
                        <BackgroundButton height={props.size} width={props.size} state={state} active={props.active}></BackgroundButton>
                        <image x={15} y={15} height="40px" width="40px" href={props.image} alt={props.alt} />
                    </g>
                </svg>
            </Tooltip>
        </div>
    ) 
}

export function PlanetHeader(props) {
    const [activeId, setActive] = useState(0)

    let missionTt = "Keine Mission verfügbar";
    if (props.missionAvailable) {
        missionTt = "Zur Mission"
    }

    return (
        <Grid h={props.height}
            w={props.width}
            templateColumns={"470px 1fr repeat(3, " + props.height + "px) 40px" }
            gap="10px"
            margin="0"
        >
            <GridItem>
                <DetailsTitle width={470} height={70} text={props.title}/>
            </GridItem>
            <GridItem colStart="3">
                <PlanetHeaderButton size = {props.height} 
                    active = {activeId === 0} 
                    setActive={()=> {
                        setActive(0);
                        props.selectBody("details");
                    }}
                    disabled = {false}
                    image='/Planet Icon white.png'
                    alt="Zur Planetenübersicht"   
                />
            </GridItem>
            <GridItem colStart="4">
                <PlanetHeaderButton size = {props.height} 
                    active = {activeId === 1} 
                    setActive={()=> {
                        setActive(1);
                        props.selectBody("places");
                    }}
                    disabled = {!props.placesActive}
                    image='/Location100.png'
                    alt={"Orte"}  
                />
            </GridItem>
            <GridItem colStart="5">
                <PlanetHeaderButton size = {props.height} 
                    active = {activeId === 2} 
                    setActive={()=> {
                        setActive(2);
                        props.selectBody("mission");
                    }}
                    disabled = {!props.missionAvailable}
                    image='/Mission Icon.png'
                    alt={missionTt}  
                />
            </GridItem>
        </Grid>
    )
}